import ClinicNavbar from "../ClinicNavbar";
import Footer from "../footer";
import ClinicSection from "../ClinicSection";
import { Col, Row, Container } from "react-bootstrap";

const Appointment = () => {
  return (
    <>
      <ClinicNavbar />
      <Container fluid className="article">
        <Row>
          <Col xs={12} sm={6} md={6} className="left-home-body p-5 ">
            <ClinicSection
              title={"Terminbuchung"}
              desc={
                "Sie können bei uns ganz bequem Termine telefonisch oder persönlich in der Praxis buchen! Bitte beachten Sie dabei folgende Punkte."
              }
              paragraph={`Unsere Ressourcen sind begrenzt, daher können wir leider nicht alle Patienten bei uns aufnehmen und konzentrieren uns auf Patienten aus unserem Einzugsgebiet Hameln. Bei Erstkontakt vereinbaren Sie bitte telefonisch unter 05151 24230 einen Termin oder besuchen Sie uns persönlich in der Praxis. So helfen Sie uns bei der Koordination und Planung der eingehenden Anmeldungen.
`}
            />
          </Col>
          <Col xs={12} sm={6} md={6} className="right-home-body"></Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default Appointment;