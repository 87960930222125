import ClinicNavbar from "../ClinicNavbar";
import Footer from "../footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import ClinicSection from "../ClinicSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faMedkit,
  faUserDoctor,
} from "@fortawesome/free-solid-svg-icons";
import DoctorCard from "../Cards/DoctorCard";

import hassanImg from "../../assets/images/staff/hassan.JPG";
import gerdImg from "../../assets/images/staff/gerd.JPG";
import avtar2 from "../../assets/images/staff/avtar2.jpg";

import staffImg1 from "../../assets/images/staff/michaela.JPG";
import staffImg2 from "../../assets/images/staff/maron.JPG";
import staffImg3 from "../../assets/images/staff/celine.JPG";
import staffImg4 from "../../assets/images/staff/susanne.JPG";
import staffImg5 from "../../assets/images/staff/aliana.JPG";

import staffImg6 from "../../assets/images/staff/diana.JPG";
import staffImg7 from "../../assets/images/staff/mayav.JPG";
import staffImg8 from "../../assets/images/staff/emily.JPG";
import avatarImg from "../../assets/images/staff/avatar.jpg";

const About = () => {
  const columnData = [
    {
      icon: faCalendar,
      desc: "Terminbuchung",
      paragraph: "Sie können bei uns ganz bequem Termine buchen!",
    },
    {
      icon: faUserDoctor,
      desc: "Qualifizierte Ärzte",
      paragraph: "Unser Team besteht aus hochqualifizierten Fachärzten.",
    },
    {
      icon: faMedkit,
      desc: "Online Rezept",
      paragraph: "Bestellen Sie ganz einfach online Ihr Rezept.",
    },
  ];

  const staffData = [
    {
      image: staffImg1,
      name: "Michaela Kühne",
      specialty: "Medizinische Fachangestellte",
    },
    {
      image: staffImg2,
      name: "Marion Ansal",
      specialty: "Medizinische Fachangestellte",
    },
    {
      image: staffImg3,
      name: "Celine Stellmacher",
      specialty: "Medizinische Fachangestellte",
    },

    {
      image: staffImg4,
      name: "Susanne Römer",
      specialty: "Medizinische Fachangestellte",
    },
    {
      image: staffImg5,
      name: "Aliana Khakimova",
      specialty: "Medizinische Fachangestellte",
    },
  ];

  const staffData2 = [
    
    {
      image: avatarImg,
      name: "Diana Burhan",
      specialty: "Auszubildende",
    },

    {
      image: staffImg6,
      name: "Mayav Bero",
      specialty: "Auszubildende",
    },

    {
      image: staffImg7,
      name: "Emily Kühne",
      specialty: "Auszubildende",
    },

    {
      image: staffImg8,
      name: "Karyna Skorokhod",
      specialty: "Auszubildende",
    },
  ];

  return (
    <>
      <ClinicNavbar />
      <div className="article">
        <Col xs={12} sm={6} md={6} className="left-home-body p-5 ">
          <ClinicSection
            title={"Über uns"}
            desc={"Lernen Sie unser Praxis-Team kennen"}
            paragraph={`Unser Praxisteam arbeitet eng zusammen. Wir erfüllen die Erwartungen unserer Patienten und die eigenen Ansprüche. Lernen sie uns kennen.`}
          />
        </Col>
        <Col className="right-home-body"></Col>
      </div>
      <Container fluid>
        <Row>
          <div className="d-flex flex-column justify-content-center align-items-center p-2">
            <ClinicSection title={"Praxis am Ring Team"} />
            <ClinicSection desc={"Heilung Im Herzen, Fürsoge Im Fokus"} />
          </div>
        </Row>

        <Row>
          {columnData.map((item, index) => (
            <Col xs={12} sm={8} md={4} key={index}>
              <div className="about-card">
                <FontAwesomeIcon
                  icon={item.icon}
                  color="#41b06e"
                  size="2xl"
                  className="m-3"
                />
                <h3>{item.desc}</h3>
                <p>{item.paragraph}</p>
              </div>
            </Col>
          ))}
        </Row>

        <Row className="doctors-bg ">
          <Row className=" p-5 bg-light bg-opacity-75 m-0">
            <div className="pb-5">
              <ClinicSection
                title={"Team"}
                desc={"Unsere Ärzte"}
                paragraph={`Unser Praxisteam besteht aus erfahrenen Ärzten, die sich um Ihre Gesundheit kümmern. Lernen Sie unsere Ärzte kennen und erfahren Sie mehr über ihre Fachgebiete und Qualifikationen.`}
              />
            </div>
            <Row>
              <Col>
                <DoctorCard
                  image={hassanImg}
                  name="Hassan Aboumaila"
                  specialty="Facharzt für Innere und Palliativmedizin"
                />
              </Col>
              <Col>
                <DoctorCard
                  image={gerdImg}
                  name="Dr.med. Gerd Kuwert"
                  specialty="Facharzt für Allgemein und Palliativmedizin"
                />
              </Col>
              <Col>
                <DoctorCard
                  image={avtar2}
                  name="Dr.med. Holger Ziegenhorn"
                  specialty="Facharzt Innere Medizin"
                />
              </Col>
            </Row>
          </Row>
        </Row>

        <Row>
        <div className="d-flex flex-column justify-content-center align-items-center p-2">
            <ClinicSection title={"Praxis am Ring Team"} />
            <ClinicSection desc={"Heilung Im Herzen, Fürsoge Im Fokus"} />
          </div>
        </Row>

  
        {/* New Staff Section */}
        <Row className="staff-bg ">
          <Row className=" p-5 bg-light bg-opacity-75 m-0">
            <div className="pb-5">
              <ClinicSection
                title={"Team"}
                desc={"Unser Personal"}
                paragraph={`Unser Praxisteam besteht aus erfahrenen Mitarbeitern, die sich um Ihre Gesundheit kümmern. Lernen Sie unser Personal kennen und erfahren Sie mehr über ihre Aufgaben und Qualifikationen.`}
              />
            </div>
            <Row>
              {staffData.map((staff, index) => (
                <Col key={index}>
                  <DoctorCard
                    image={staff.image}
                    name={staff.name}
                    specialty={staff.specialty}
                  />
                </Col>
              ))}
            </Row>

            <Row>
              {staffData2.map((staff, index) => (
                <Col key={index}>
                  <DoctorCard
                    image={staff.image}
                    name={staff.name}
                    specialty={staff.specialty}
                  />
                </Col>
              ))}
            </Row>
          </Row>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default About;
